import React, {Component} from 'react'
import intl from 'react-intl-universal'
import './App.scss'
import TopBar from './components/TopBar/'
import Start from './components/Start/'
import LabStart from './components/Lab/LabStart'
import Lab from './components/Lab'
import Facts from './components/Facts'
import SaveReport from './components/Reports/SaveReport'
import Reports from './components/Reports/'
import Sessions from './components/Sessions/'
import modes from './utils/modes'
import ceilingMaterials from './data/ceilingMaterials'
import wallMaterials from './data/wallMaterials'
import floorMaterials from './data/floorMaterials'
import AbsorbantRoom from './utils/AbsorbantRoom'
import AbsorbantWall from './utils/AbsorbantWall'
import { AuthContext } from './shared/provider/AuthProvider .js'
import Backdrop from './components/LogIn/loadingBackdrop.js'
import MiddlewareService from './axios/service/MiddlewareService.js'

require('intl/locale-data/jsonp/en.js')
require('intl/locale-data/jsonp/sv.js')

const locales = {
  "en-US": require('./translations/en.json'),
  "sv-SE": require('./translations/sv.json'),
};

class App extends Component {

  constructor(props) {

    super(props)

    this.views = {
      start: 'start',
      facts: 'facts',
      labStart: 'labStart',
      lab: 'lab',
      reports: 'reports',
      saveReport: 'saveReport',
      sessions: 'sessions',
      settings: 'settings'
    }

    this.labViews = {
      lab: 'lab',
      report: 'report',
    }

    let absorbantRooms = []
    absorbantRooms.push(new AbsorbantRoom(0))
    absorbantRooms.push(new AbsorbantRoom(1, false))
    absorbantRooms.push(new AbsorbantRoom(2, false))

    let absorbantWalls = []
    absorbantWalls.push(new AbsorbantWall("1", "front"))
    absorbantWalls.push(new AbsorbantWall("2", "right"))
    absorbantWalls.push(new AbsorbantWall("3", "back"))
    absorbantWalls.push(new AbsorbantWall("4", "left"))



    // Test values
    // absorbantRooms[1].absorbantWalls[0].layerOne.productIndex = 0
    // absorbantRooms[1].absorbantWalls[0].layerOne.width = 2
    // absorbantRooms[1].absorbantWalls[0].layerOne.height = 2
    // absorbantRooms[1].absorbantWalls[0].layerOne.product = products[0]
    // const images = this.importAll(require.context('./img/akemi/', false, /\.(png|jpe?g|svg)$/))

    this.defaults = {
      roomWidth: 5,
      roomDepth: 5,
      roomHeight: 2.5,
      ceilingMaterialIndex: 0,
      wallMaterialIndex: 0,
      floorMaterialIndex: 0,
      absorbantRooms: absorbantRooms,
      activeAbsorbantRoomIndex: 0,
      leftmostAbsorbantRoomIndex: 0,
    }

    this.state = {
      currentLocale: 'sv-SE',
      prevLocale: null,
      initDone: false,
      currentSession: null,
      readyToSave: false,
      hasBeenSaved: false,
      updateCache: false,
      currentView: this.views.start,
      currentLabView: this.labViews.lab,
      projectInputMode: "textiles",
      roomWidth: 5,
      roomDepth: 5,
      roomHeight: 2.5,
      ceilingMaterialIndex: 0,
      wallMaterialIndex: 0,
      floorMaterialIndex: 0,

      absorbantRooms: absorbantRooms,
      activeAbsorbantRoomIndex: 0,
      leftmostAbsorbantRoomIndex: 0,

      absorbantWalls: absorbantWalls,
    }

    this.restoreFromCache = this.restoreFromCache.bind(this)
    this.resetToDefaults = this.resetToDefaults.bind(this)

    this.onViewChanged = this.onViewChanged.bind(this)
    this.onLabViewChanged = this.onLabViewChanged.bind(this)

    this.setProjectInputMode = this.setProjectInputMode.bind(this)

    this.onRoomNameChanged = this.onRoomNameChanged.bind(this)
    this.onRoomWidthChanged = this.onRoomWidthChanged.bind(this)
    this.onRoomDepthChanged = this.onRoomDepthChanged.bind(this)
    this.onRoomHeightChanged = this.onRoomHeightChanged.bind(this)

    this.onCeilingMaterialChanged = this.onCeilingMaterialChanged.bind(this)
    this.onWallMaterialChanged = this.onWallMaterialChanged.bind(this)
    this.onFloorMaterialChanged = this.onFloorMaterialChanged.bind(this)

    this.setLeftmostAbsorbantRoom = this.setLeftmostAbsorbantRoom.bind(this)
    this.onAbsorbantRoomChanged = this.onAbsorbantRoomChanged.bind(this)

    this.onActivateAbsorbantWall = this.onActivateAbsorbantWall.bind(this)

    this.onCurrentSessionChanged = this.onCurrentSessionChanged.bind(this)

    this.onLocaleChanged = this.onLocaleChanged.bind(this)

    this.setHasBeenSaved = this.setHasBeenSaved.bind(this)

  }

  setHasBeenSaved(saved) {
    this.setState({
      hasBeenSaved: saved,
      updateCache: true,
    })
  }

  onCurrentSessionChanged(session) {
    let rooms = this.state.absorbantRooms
    rooms.forEach(room => {
      room.absorbantWalls.forEach(wall => {
        wall.layerOne.readyToSave = false
        wall.layerTwo.readyToSave = false
      })
    });

    // let walls = this.state.absorbantWalls
    // for (let index in walls) {
    //   walls[index].layerOne.readyToSave = false
    //   walls[index].layerTwo.readyToSave = false
    // }
    this.setState({
      currentSession: session,
      readyToSave: false,
      // absorbantWalls: walls,
      absorbantRooms: rooms,
    })
  }

  onViewChanged(view) {


    this.setState({
      currentView: view,
      currentLabView: this.labViews.lab
    })
  }

  onLabViewChanged(view) {

    this.setState({
      currentLabView: view
    })
  }

  setProjectInputMode(mode) {
    this.setState({
      projectInputMode: mode
    })
  }

  onRoomNameChanged(name, roomIndex) {
    const rooms = this.state.absorbantRooms
    rooms[roomIndex].name = name
    this.setState({
      absorbantRooms: rooms
    })
  }

  // Room Volume
  onRoomWidthChanged(value) {
    value = Number(value)
    const readyToSave = this.atLeastOneProductActive()
    this.setState({
      roomWidth: value,
      readyToSave: readyToSave,
      updateCache: true,
    })

    this.limitAbsorbantWallWidths(value)

  }

  onRoomDepthChanged(value) {
    value = Number(value)
    const readyToSave = this.atLeastOneProductActive()
    this.setState({
      roomDepth: value,
      readyToSave: readyToSave,
      updateCache: true,
    })

    this.limitAbsorbantWallDepths(value)
  }

  onRoomHeightChanged(value) {
    value = Number(value)
    const readyToSave = this.atLeastOneProductActive()
    this.setState({
      roomHeight: value,
      readyToSave: readyToSave,
      updateCache: true,
    })

    this.limitAbsorbantWallHeights(value)
  }

  // Surface materials
  onCeilingMaterialChanged(index) {
    const readyToSave = this.atLeastOneProductActive()
    this.setState({
      ceilingMaterialIndex: index,
      readyToSave: readyToSave,
      updateCache: true,
    })
  }

  onWallMaterialChanged(index) {
    const readyToSave = this.atLeastOneProductActive()
    this.setState({
      wallMaterialIndex: index,
      readyToSave: readyToSave,
      updateCache: true,
    })
  }

  onFloorMaterialChanged(index) {
    const readyToSave = this.atLeastOneProductActive()
    this.setState({
      floorMaterialIndex: index,
      readyToSave: readyToSave,
      updateCache: true,
    })
  }

  atLeastOneProductActive() {
    let atLeastOneProductActive = false
      this.state.absorbantRooms.forEach(room => {
        room.absorbantWalls.forEach(wall => {
          if (
            (wall.layerOne.productIndex >= 0 && wall.layerOne.width > 0 && wall.layerOne.height > 0)
            || (wall.layerTwo.productIndex >= 0 && wall.layerTwo.width > 0 && wall.layerTwo.height > 0)
          ) {
            atLeastOneProductActive = true
          }
        })
      })
      return atLeastOneProductActive
  }

  // Textile
  onActivateAbsorbantWall(roomIndex, wallIndex) {
    let rooms = this.state.absorbantRooms
    let room = rooms[roomIndex]
    room.activeAbsorbantWallIndex = wallIndex
    this.setState({
      absorbantRooms: rooms,
      activeAbsorbantRoomIndex: roomIndex,
      leftmostAbsorbantRoomIndex: roomIndex,
    })

    if (room.empty) {
      this.setProjectInputMode(modes.input.volume)
    }
    else {
      this.setProjectInputMode(modes.input.textiles)
    }
  }

  setLeftmostAbsorbantRoom(roomIndex) {

    this.setState({
      activeAbsorbantRoomIndex: roomIndex,
      leftmostAbsorbantRoomIndex: roomIndex,
    })

    let room = this.state.absorbantRooms[roomIndex]
    if (room.empty) {
      this.setProjectInputMode(modes.input.volume)
    }
    else {
      this.setProjectInputMode(modes.input.textiles)
    }
  }

  onAbsorbantRoomsChanged(rooms) {

    this.setState({
      absorbantRooms: rooms,
      updateCache: true,
    })

  }

  onAbsorbantRoomChanged(roomIndex, wallIndex, wall, layerOneWasChanged, layerTwoWasChanged) {

    let rooms = this.state.absorbantRooms
    let readyToSave = false

    if (wall.layerOne.productIndex === -1) {
      wall.layerOne.width = 0
      wall.layerOne.height = 0
    }
    if (wall.layerTwo.productIndex === -1) {
      wall.layerTwo.width = 0
      wall.layerTwo.height = 0
    }

    if (
      (
      layerOneWasChanged &&
      wall.layerOne.productIndex >= 0 &&
      wall.layerOne.width > 0 &&
      wall.layerOne.height > 0) ||
      (
      layerTwoWasChanged &&
      wall.layerTwo.productIndex >= 0 &&
      wall.layerTwo.width > 0 &&
      wall.layerTwo.height > 0)
      ) {
      readyToSave = true
    }

    let layerOneReadyToSave = false
    if (
      layerOneWasChanged &&
      wall.layerOne.productIndex >= 0 &&
      wall.layerOne.width > 0 &&
      wall.layerOne.height > 0) {
        layerOneReadyToSave= true
    }

    let layerTwoReadyToSave = false
    if(layerTwoWasChanged &&
      wall.layerTwo.productIndex >= 0 &&
      wall.layerTwo.width > 0 &&
      wall.layerTwo.height > 0) {
        layerTwoReadyToSave = true
    }

    wall.layerOne.readyToSave = layerOneReadyToSave
    wall.layerTwo.readyToSave = layerTwoReadyToSave




    rooms[roomIndex].absorbantWalls[wallIndex] = wall

    this.setState({
      absorbantRooms: rooms,
      readyToSave: readyToSave,
      updateCache: true,
    })
  }

  limitAbsorbantWallWidths(roomWidth) {
    const absorbantRooms = this.state.absorbantRooms
    absorbantRooms.forEach((room) => {
      if (!room.empty) {
        room.absorbantWalls.forEach((wall) => {
          if (wall.layerOne.width > roomWidth) {
            wall.layerOne.width = roomWidth
          }
          if (wall.layerTwo.width > roomWidth) {
            wall.width = roomWidth
          }
        })
      }
    })
    this.setState({
      absorbantRooms: absorbantRooms
    })
  }

  limitAbsorbantWallDepths(roomDepth) {
    const absorbantRooms = this.state.absorbantRooms
    absorbantRooms.forEach((room) => {
      if (!room.empty) {
        room.absorbantWalls.forEach((wall) => {
          if (wall.layerOne.width > roomDepth) {
            wall.layerOne.width = roomDepth
          }
          if (wall.layerTwo.width > roomDepth) {
            wall.layerTwo = roomDepth
          }
        })
      }
    })
    this.setState({
      absorbantRooms: absorbantRooms
    })
  }

  limitAbsorbantWallHeights(roomHeight) {
    const absorbantRooms = this.state.absorbantRooms
    absorbantRooms.forEach((room) => {
      if (!room.empty) {
        room.absorbantWalls.forEach((wall) => {
          if (wall.layerOne.height > roomHeight) {
            wall.layerOne.height = roomHeight
          }
          if (wall.layerTwo.height > roomHeight) {
            wall.layerTwo = roomHeight
          }
        })
      }
    })
    this.setState({
      absorbantRooms: absorbantRooms
    })
  }

  loadLocales() {
    intl.init({
      currentLocale: this.state.currentLocale,
      locales,
    })
    .then(() => {
      this.setState({
        initDone: true
      })
      document.title = intl.get('app.name')
    })
  }

  onLocaleChanged(locale) {
    this.setState({
      currentLocale: locale
    })
    localStorage.setItem('currentLocale', locale)
  }

  async restoreFromCache() {
    const currentCache = JSON.parse(localStorage.getItem('currentCache'))

    if (currentCache) {

      if (currentCache.hasBeenSaved) {
        this.setState({
          hasBeenSaved: currentCache.hasBeenSaved
        })
      }

      if (currentCache.roomWidth) {
        this.onRoomWidthChanged(parseFloat(currentCache.roomWidth))
      }

      if  (currentCache.roomDepth) {
        this.onRoomDepthChanged(parseFloat(currentCache.roomDepth))
      }

      if (currentCache.roomHeight) {
        this.onRoomHeightChanged(parseFloat(currentCache.roomHeight))
      }

      if (currentCache.ceilingMaterialIndex) {
        this.onCeilingMaterialChanged(parseInt(currentCache.ceilingMaterialIndex))
      }

      if (currentCache.wallMaterialIndex) {
        this.onWallMaterialChanged(parseInt(currentCache.wallMaterialIndex))
      }

      if  (currentCache.floorMaterialIndex) {
        this.onFloorMaterialChanged(parseInt(currentCache.floorMaterialIndex))
      }

      if (currentCache.absorbantRooms) {
        this.onAbsorbantRoomsChanged(currentCache.absorbantRooms)
      }


    }
    else {
      this.setState({
        hasBeenSaved: true
      })
    }
  }

  resetToDefaults() {
      this.onRoomWidthChanged(parseFloat(this.defaults.roomWidth))
      this.onRoomDepthChanged(parseFloat(this.defaults.roomDepth))
      this.onRoomHeightChanged(parseFloat(this.defaults.roomHeight))
      this.onCeilingMaterialChanged(parseInt(this.defaults.ceilingMaterialIndex))
      this.onWallMaterialChanged(parseInt(this.defaults.wallMaterialIndex))
      this.onFloorMaterialChanged(parseInt(this.defaults.floorMaterialIndex))

      let absorbantRooms = []
      absorbantRooms.push(new AbsorbantRoom(0))
      absorbantRooms.push(new AbsorbantRoom(1, false))
      absorbantRooms.push(new AbsorbantRoom(2, false))
      this.onAbsorbantRoomsChanged(absorbantRooms)

      this.setState({
        hasBeenSaved: true
      })
  }

  updateCache() {

    let currentCache = {}

    if (localStorage.getItem('currentCache')) {
      currentCache = JSON.parse(localStorage.getItem('currentCache'))
    }
    currentCache.roomWidth = this.state.roomWidth
    currentCache.roomDepth = this.state.roomDepth
    currentCache.roomHeight = this.state.roomHeight
    currentCache.ceilingMaterialIndex = this.state.ceilingMaterialIndex
    currentCache.wallMaterialIndex = this.state.wallMaterialIndex
    currentCache.floorMaterialIndex = this.state.floorMaterialIndex
    currentCache.absorbantRooms = this.state.absorbantRooms
    currentCache.hasBeenSaved = this.state.hasBeenSaved

    localStorage.setItem('currentCache', JSON.stringify(currentCache))
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.prevLocale !== this.state.currentLocale) {
      this.loadLocales()
      this.setState((state) => {
        return {
          prevLocale: state.currentLocale
        }
      })
    }
    if (this.state.updateCache && !prevState.updateCache) {
      this.updateCache()
      this.setState({
        updateCache: false
      })
    }
  }

  componentDidMount() {



    if (localStorage.getItem('currentLocale')) {
      this.setState({
        currentLocale: localStorage.getItem('currentLocale')
      })
    }
    else if (intl.determineLocale() === 'sv' || intl.determineLocale() === 'sv-SE' || intl.determineLocale() === 'se-FI') {
      this.setState({
        currentLocale: 'sv-SE'
      })
    }
    else {
      this.setState({
        currentLocale: 'en-US'
      })
    }

    this.loadLocales()

    let currentSessionIndex = 0
    if (localStorage.getItem('currentSessionIndex')) {
      currentSessionIndex = localStorage.getItem('currentSessionIndex')
    }

    this.session = null
    if (localStorage.getItem('sessions')) {
      this.session = JSON.parse(localStorage.getItem('sessions'))[currentSessionIndex] 
    }

    if (this.session) {
      this.setState({
        currentSession: this.session
      })
    }

    this.restoreFromCache()

    this.updateCache()

  }

  render() {
    
    
    let view = <></>

    // CurrentUser must have a value otherwise user will be redirected to Sven
    const { currentUser, token } = this.context;
    if (!currentUser) {
      window.location.href = process.env.REACT_APP_API_BASE_URL_SVEN;
      return <Backdrop/>;
    }

    let tokenexpired = false; 
    const tokenExpires = token.expires; // Fetch token expires from AuthContext

    // If token is expired sessionstorage will be cleard 
    function clearSessionStorageIfExpired(tokenExpires) {
        const currentDate = new Date();
        // If you want to log tokenExpires, and see how often its checked you can do it here:
        // console.log(tokenExpires, currentDate)
        if (tokenExpires < currentDate) {
          console.log('Token is expired ')
          tokenexpired = true;
            sessionStorage.clear();
        }
    }

    clearSessionStorageIfExpired(new Date(tokenExpires)); // new Date converts to same time format as current Date 

    // If token is expired you get redirected to Sven-login "Loading..." is visible while redirecting
    if (tokenexpired)
    {
      window.location.href = process.env.REACT_APP_API_BASE_URL_SVEN;
      return <Backdrop/>;
    }

    // If token is expired sessionstorage will be cleard when leaving the page
    window.addEventListener('beforeunload', () => {
      clearSessionStorageIfExpired(new Date(tokenExpires));
    });
    
    
    if (this.state.currentView === this.views.start) {
      view = <Start
        views={this.views}
        onViewChanged={this.onViewChanged}
        hasBeenSaved={this.state.hasBeenSaved}
      />
    }

    else if (this.state.currentView === this.views.facts) {
      view = <Facts />
    }

    else if (this.state.currentView === this.views.labStart) {
      view = <LabStart
        resetToDefaults={this.resetToDefaults}
        views={this.views}
        onViewChanged={this.onViewChanged}
        restoreFromCache={this.restoreFromCache}
        setLeftmostAbsorbantRoom = {this.setLeftmostAbsorbantRoom}
        setHasBeenSaved = {this.setHasBeenSaved}
      />
    }

    else if (this.state.currentView === this.views.lab) {
      let readyToSave = this.state.readyToSave
      for (let index in this.state.absorbantWalls) {
        if (this.state.absorbantWalls[index].layerOne.readyToSave || this.state.absorbantWalls[index].layerTwo.readyToSave) {
          readyToSave = true
          break;
        }
      }

      view =
        <Lab
          views={this.views}
          hasBeenSaved={this.state.hasBeenSaved}
          setHasBeenSaved={this.hasBeenSaved}
          onViewChanged={this.onViewChanged}
          currentLabView={this.state.currentLabView}
          labViews={this.labViews}
          onLabViewChanged={this.onLabViewChanged}
          resetToDefaults={this.resetToDefaults}
          absorbantRooms = {this.state.absorbantRooms}
          leftmostAbsorbantRoomIndex = {this.state.leftmostAbsorbantRoomIndex}
          setLeftmostAbsorbantRoom = {this.setLeftmostAbsorbantRoom}

          onRoomNameChanged={this.onRoomNameChanged}
          roomWidth={this.state.roomWidth}
          roomDepth={this.state.roomDepth}
          roomHeight={this.state.roomHeight}
          ceilingMaterial = {ceilingMaterials[this.state.ceilingMaterialIndex]}
          wallMaterial = {wallMaterials[this.state.wallMaterialIndex]}
          floorMaterial = {floorMaterials[this.state.floorMaterialIndex]}
          onActivateAbsorbantWall = {this.onActivateAbsorbantWall}

          onCurrentSessionChanged={this.onCurrentSessionChanged}
          session={this.state.currentSession}
          readyToSave={readyToSave}
          projectInputMode={this.state.projectInputMode}
          setProjectInputMode={this.setProjectInputMode}
          onRoomWidthChanged={this.onRoomWidthChanged}
          onRoomDepthChanged={this.onRoomDepthChanged}
          onRoomHeightChanged={this.onRoomHeightChanged}
          ceilingMaterialIndex = {this.state.ceilingMaterialIndex}
          onCeilingMaterialChanged = {this.onCeilingMaterialChanged}
          wallMaterialIndex = {this.state.wallMaterialIndex}
          onWallMaterialChanged = {this.onWallMaterialChanged}
          floorMaterialIndex = {this.state.floorMaterialIndex}
          onFloorMaterialChanged = {this.onFloorMaterialChanged}
          // products = {products}
          activeAbsorbantRoom = {this.state.absorbantRooms[this.state.activeAbsorbantRoomIndex]}
          onAbsorbantRoomChanged = {this.onAbsorbantRoomChanged}
        >
      </Lab>

    }
    else if (this.state.currentView === this.views.reports) {
      view = <>
        <Reports
          setHasBeenSaved={this.setHasBeenSaved}
          hasBeenSaved={this.state.hasBeenSaved}
          session={this.state.currentSession}
          onCurrentSessionChanged={this.onCurrentSessionChanged}
          views={this.views}
          onViewChanged={this.onViewChanged}
          restoreFromCache={this.restoreFromCache}
          resetToDefaults={this.resetToDefaults}
          setLeftmostAbsorbantRoom = {this.setLeftmostAbsorbantRoom}
        />
      </>
    }
    else if (this.state.currentView === this.views.sessions) {
      view = <>
        <Sessions
          onCurrentSessionChanged={this.onCurrentSessionChanged}
        />
      </>
    }
   
    else if (this.state.currentView === this.views.saveReport) {
      view = <>
        <SaveReport
          session={this.state.currentSession}
          views={this.views}
          onViewChanged={this.onViewChanged}
          setHasBeenSaved={this.setHasBeenSaved}
        />
      </>
    }



    return (
      this.state.initDone &&
      <div className="App">
        <TopBar
          views={this.views}
          onChange={this.onViewChanged}
          currentView={this.state.currentView}
          onLocaleChanged={this.onLocaleChanged}
          currentLocale={this.state.currentLocale}
          hasBeenSaved={this.state.hasBeenSaved}
        />

        {view}
      </div>
    )
  }
}

App.contextType = AuthContext;

export default App
