import React, {Component} from 'react'
import intl from 'react-intl-universal'

import styles from './Select.module.scss'



class Select extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        
    }

    handleChange(e) {
        this.props.onChange(e.target.value)
    }

    getOptions() {
        var options = [<option key={-1} value={-1}>{intl.get('general.select')}...</option>]
        
        for (var index in this.props.names) {
            options.push(<option key={index} value={index}>{this.props.names[index]}</option>)
        }

        return options
        
    }

    render() {
        return (
            <div className={styles.container}>
                <label>
                    {this.props.label}
                <select className={`${styles.select}`} onChange={this.handleChange} value={this.props.value}>
                    {this.getOptions()}
                </select>
                </label>
            </div>
        )
    }
}

export default Select